<template v-if="this.open">
  <page-header-wrapper @back="back">
    <template v-slot:breadcrumb>
      {{ formTitle }}
    </template>
    <template v-slot:title>
      {{ formTitle }}
    </template>
    <div class="bg-white padding-lr"ref="container">
      <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left" :model="form"
                    :rules="rules">
        <a-collapse v-model="activeKey">
          <a-collapse-panel key="1" :header="$t('店铺.基础信息')" class="goods-collapse">
            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.店铺名称')" prop="shopName">
                  <a-input v-model="form.shopName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('店铺.店铺名称')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.客服电话')" prop="kfPhone">
                  <a-input v-model="form.kfPhone" :placeholder="$t('通用.输入.请输入')+$t('店铺.客服电话')"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.入驻类型')" prop="settleType">
                  <a-select style="width: 100%" v-model="form.settleType" placeholder="请选择入驻类型">
                    <a-select-option v-for="(item, index) in this.customDict.ShopSettleTypeEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="$t('店铺.店铺分类')" prop="categoryId">
                  <template>
                    <a-cascader :options="categoryList"
                                :field-names="{ label: 'categoryName', value: 'id', children: 'children'}"
                                :placeholder="$t('通用.输入.请输入')+$t('店铺.店铺分类')"
                                style="width: 100%" v-model="categorySelected" allowClear @change="onCategoryChange">
                    </a-cascader>
                  </template>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.主营行业')" prop="shopQc">
                  <a-select style="width: 100%" v-model="form.shopQc" placeholder="请选择主营行业">
                    <a-select-option v-for="(item, index) in shopQcList" :value="item.id" :key="index">
                      {{ item.categoryName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.入驻时长')" prop="timeOfEntry">
                  <a-select style="width: 100%" v-model="form.timeOfEntry" placeholder="请选择入驻时长">
                    <a-select-option v-for="(item, index) in timeOfEntryList" :value="item.id" :key="index">
                      {{ item.duration }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.店铺logo')" prop="shopLogo">
                  <a-upload
                    name="shopLogo"
                    listType="picture-card"
                    :multiple="false"
                    :show-upload-list="false"
                    :preview="handlePreview"
                    :before-upload="beforeOssUpload"
                    :customRequest="coverImgUpload">
                    <img
                      v-if="form.shopLogo"
                      :src="form.shopLogo"
                      alt="shopLogo"
                      style="height: 100px; width: 100px;"/>
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'"/>
                      <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
                    </div>
                  </a-upload>
                  <span>推荐尺寸 375 x 375</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.店铺背景图')" prop="shopBackground">
                  <a-upload
                    name="shopBackground"
                    listType="picture-card"
                    :multiple="false"
                    :show-upload-list="false"
                    :preview="handlePreview"
                    :before-upload="beforeOssUpload"
                    :customRequest="coverImgUpload1">
                    <img
                      v-if="form.shopBackground"
                      :src="form.shopBackground"
                      alt="shopBackground"
                      style="height: 100px; width: 100px;"/>
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'"/>
                      <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
                    </div>
                  </a-upload>
                  <span>推荐尺寸 375 x 375</span>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.关键词')" prop="shopKeywords">
                  <a-input v-model="form.shopKeywords" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('店铺.关键词')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.店铺状态')" prop="shopState">
                  <a-select style="width: 100%" v-model="form.shopState" placeholder="请选择入驻类型">
                    <a-select-option v-for="(item, index) in this.customDict.ShopStateEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.店铺简介')" prop="shopDescription">
                  <a-input v-model="form.shopDescription" :placeholder="$t('通用.输入.请输入')+$t('店铺.店铺简介')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.公司申请类型')" prop="companyType">
                  <a-select style="width: 100%" v-model="form.companyType" placeholder="请选择入驻类型">
                    <a-select-option v-for="(item, index) in this.customDict.CompanyTypeEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.营业时间')" prop="businessTime">
                  <a-input v-model="form.businessTime" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('店铺.营业时间')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="$t('店铺.平台抽成比例')" prop="platformRatio">
                  <a-input v-model="form.platformRatio" @change="limitPrice()" suffix="¥"
                           :placeholder="$t('通用.输入.请输入')+$t('店铺.平台抽成比例')"/>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-collapse-panel>
          <a-collapse-panel key="2" :header="$t('店铺.地址信息')" class="goods-collapse">
            <a-row>
              <a-col :span="15">
                <a-form-model-item :label="this.$t('店铺.详细地区')" prop="shopAddress">
                  <a-input v-model="form.shopAddress" :placeholder="$t('通用.输入.请输入')+$t('店铺.详细地区')" :disabled="true"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item :label="this.$t('店铺.店铺时间')" prop="shopCreateTime">
                  <a-input :disabled="true" v-model="form.createTime" :placeholder="$t('通用.输入.请输入')+$t('店铺.店铺时间')" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-model-item :label="this.$t('店铺.店铺所在省份')" prop="shopAreap">
                  <a-input v-model="form.shopAreap" :placeholder="$t('通用.输入.请输入')+$t('店铺.店铺所在省份')" :disabled="true"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item :label="this.$t('店铺.店铺所在城市')" prop="shopAreac">
                  <a-input v-model="form.shopAreac" :placeholder="$t('通用.输入.请输入')+$t('店铺.店铺所在城市')" :disabled="true"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item :label="this.$t('店铺.店铺所在县')" prop="shopAreax">
                  <a-input v-model="form.shopAreax" :placeholder="$t('通用.输入.请输入')+$t('店铺.店铺所在县')" :disabled="true"/>
                </a-form-model-item>
              </a-col>
            </a-row>

          </a-collapse-panel>
          <a-collapse-panel key="3" :header="$t('店铺.入驻资料')" class="goods-collapse">
            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.法人姓名')" prop="contactsName">
                  <a-input v-model="form.contactsName" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('店铺.法人姓名')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.法人手机号')" prop="contactsPhone">
                  <a-input v-model="form.contactsPhone" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('店铺.法人手机号')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.法人邮箱')" prop="contactsEmail">
                  <a-input v-model="form.contactsEmail" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('店铺.法人邮箱')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.法人身份证号')" prop="contactsCardNo">
                  <a-input v-model="form.contactsCardNo" :maxLength="50"
                           :placeholder="$t('通用.输入.请输入')+$t('店铺.法人身份证号')"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.真实姓名')" prop="settlementBankUserName">
                  <a-input v-model="form.settlementBankUserName" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('店铺.真实姓名')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.结算账户')" prop="settlementBankAccountNumber">
                  <a-input v-model="form.settlementBankAccountNumber" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('店铺.结算账户')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.银行预留手机号')" prop="bankUserPhone">
                  <a-input v-model="form.bankUserPhone" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('店铺.银行预留手机号')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.结算银行开户名')" prop="settlementBankAccountName">
                  <a-input v-model="form.settlementBankAccountName" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('店铺.结算银行开户名')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.结算开户银行支行名称')" prop="settlementBankName">
                  <a-input v-model="form.settlementBankName" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('店铺.结算开户银行支行名称')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.结算开户银行所在地')" prop="settlementBankAddress">
                  <a-input v-model="form.settlementBankAddress" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('店铺.结算开户银行所在地')"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.身份证正面')" prop="contactsCardFront">
                  <a-upload
                    name="contactsCardFront"
                    listType="picture-card"
                    :multiple="false"
                    :show-upload-list="false"
                    :preview="handlePreview"
                    :before-upload="beforeOssUpload"
                    :customRequest="coverImgUpload2">
                    <img
                      v-if="form.contactsCardFront"
                      :src="form.contactsCardFront"
                      alt="contactsCardFront"
                      style="height: 100px; width: 100px;"/>
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'"/>
                      <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
                    </div>
                  </a-upload>
                  <span>推荐尺寸 375 x 375</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.身份证反面')" prop="contactsCardContrary">
                  <a-upload
                    name="contactsCardContrary"
                    listType="picture-card"
                    :multiple="false"
                    :show-upload-list="false"
                    :preview="handlePreview"
                    :before-upload="beforeOssUpload"
                    :customRequest="coverImgUpload3">
                    <img
                      v-if="form.contactsCardContrary"
                      :src="form.contactsCardContrary"
                      alt="contactsCardContrary"
                      style="height: 100px; width: 100px;"/>
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'"/>
                      <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
                    </div>
                  </a-upload>
                  <span>推荐尺寸 375 x 375</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.营业执照')" prop="businessLicenceNumberElectronic">
                  <a-upload
                    name="businessLicenceNumberElectronic"
                    listType="picture-card"
                    :multiple="false"
                    :show-upload-list="false"
                    :preview="handlePreview"
                    :before-upload="beforeOssUpload"
                    :customRequest="coverImgUpload4">
                    <img
                      v-if="form.businessLicenceNumberElectronic"
                      :src="form.businessLicenceNumberElectronic"
                      alt="businessLicenceNumberElectronic"
                      style="height: 100px; width: 100px;"/>
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'"/>
                      <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
                    </div>
                  </a-upload>
                  <span>推荐尺寸 375 x 375</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('店铺.已结算金额')" prop="settlementAccount">
                  <a-input v-model="form.settlementAccount" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('店铺.已结算金额')"/>
                </a-form-model-item>
              </a-col>
            </a-row>

          </a-collapse-panel>
        </a-collapse>

        <a-divider></a-divider>
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              {{ $t('通用.按钮.保存') }}
            </a-button>
            <a-button type="dashed" @click="cancel">
              {{ $t('通用.按钮.取消') }}
            </a-button>
          </a-space>
        </div>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl"/>
        </a-modal>
        <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
          <video style="width: 100%" :src="previewUrl" ref="myVideo"
                 :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
        </a-modal>
      </a-form-model>
    </div>
  </page-header-wrapper>
</template>


<!--<template>
<a-drawer width="45%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">

<a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">

  <a-form-model-item :label="this.$t('店铺.关键词')" prop="shopKeywords">
    <a-input v-model="form.shopKeywords" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('店铺.关键词')"/>
  </a-form-model-item>

  <a-form-model-item prop="shopAreac">
        <span slot="label">
          <a-tooltip>
            {{ $t('店铺.店铺所在城市') }}<template slot="title">{{ $t('店铺.店铺所在城市') }}</template>
          </a-tooltip>
        </span>
    <a-input v-model="form.shopAreac" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('店铺.店铺所在城市')"/>
  </a-form-model-item>
</a-form-model>
</a-drawer>
</template>-->
<script>
import {getShop, addShop, updateShop} from '@/api/shop/shop'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
import Template from "@/views/sms/template";
import {allBrand} from "@/api/goods/brand";
import {listShopCategory} from "@/api/shop/shopCategory";
import {listShopQc} from '@/api/shop/shopQc'
import {listShopEntry} from '@/api/shop/shopEntry'
import events from "@/components/MultiTab/events";
import bus from "@/utils/bus";

export default {
  name: 'CreateForm',
  props: {},
  components: {
    Template,
    CustomDictTag,
  },
  data() {
    return {
      /*分类的回显*/
      categorySelected: [],
      categoryList: [{id: "0", categoryName: '', children: []}],
      shopQcList: [],
      timeOfEntryList: [],
      activeKey: [1, 2, 3, 4],
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        shopName: null,

        shopLogo: null,

        shopBackground: null,

        shopKeywords: null,
        businessTime:null,

        shopQc: null,

        timeOfEntry: null,

        shopAreap: null,

        shopAreac: null,

        shopAreax: null,

        shopAddress: null,

        longitude: null,

        latitude: null,

        shopDescription: null,

        kfPhone: null,

        companyType: null,

        settleType: null,

        shopType: null,

        shopRecommend: null,

        userId: null,

        categoryId: null,

        categoryListId: null,

        shopState: null,

        shopCloseInfo: null,

        shopCollect: null,

        attentionNum: null,

        shopAccount: null,

        settlementAccount: null,

        shopCreateTime: null,

        shopEndTime: null,

        goodsDescribeScore: null,

        shopDescribeScore: null,

        logisticsScore: null,

        shopScore: null,

        thatViews: null,

        kfAccount: null,

        alarmGoodsStock: null,

        alarmGoodsSkuStock: null,

        weatherBzj: null,

        moneyBzj: null,

        platformRatio: null,

        contactsName: null,

        contactsPhone: null,

        contactsEmail: null,

        contactsCardNo: null,

        contactsCardHand: null,

        contactsCardFront: null,

        contactsCardContrary: null,

        businessLicenceNumber: null,

        companyName: null,

        companyAreap: null,

        companyAreac: null,

        companyAreax: null,

        companyAddressDetail: null,

        socialCreditCode: null,

        authCertificate: null,

        businessSphere: null,

        businessLicenceNumberElectronic: null,

        bankCardNoType: null,

        settlementBankUserName: null,

        settlementBankAccountNumber: null,

        bankUserPhone: null,

        settlementBankAccountName: null,

        settlementBankName: null,

        settlementBankAddress: null,

        accountInstCity: null,

        accountInstProvince: null,

        createTime: null,

        remark: null,

        trademarkCertificate: null,

        shopProtocol: null,

        businessLicense: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        shopName: [
          {required: true, message: this.$t('店铺.店铺名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopLogo: [
          {required: true, message: this.$t('店铺.店铺logo') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopBackground: [
          {required: true, message: this.$t('店铺.店铺背景图') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopKeywords: [
          {required: true, message: this.$t('店铺.搜索店铺关键词') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        timeOfEntry: [
          {required: true, message: this.$t('店铺.入驻时长,外键') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopAddress: [
          {required: true, message: this.$t('店铺.详细地区') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        /*shopDescription: [
          {required: true, message: this.$t('店铺.店铺简介') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],*/
        companyType: [
          {required: true, message: this.$t('店铺.公司申请类型1.个人2.公司') + this.$t('通用.文本.不能为空'), trigger: 'change'}
        ],
        settleType: [
          {required: true, message: this.$t('店铺.入驻类型1.线上商家2.线下商家') + this.$t('通用.文本.不能为空'), trigger: 'change'}
        ],
        shopType: [
          {required: true, message: this.$t('店铺.是否自营 0：否 1：是') + this.$t('通用.文本.不能为空'), trigger: 'change'}
        ],
        shopRecommend: [
          {required: true, message: this.$t('店铺.是否推荐 0：否 1：是') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        userId: [
          {required: true, message: this.$t('店铺.店主id,外键 (user.id)') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        /*categoryId: [
          {required: true, message: this.$t('店铺.店铺分类id') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],*/
        shopState: [
          {required: true, message: this.$t('店铺.店铺状态，0关闭，1开启，2审核中') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopCloseInfo: [
          {required: true, message: this.$t('店铺.店铺关闭原因') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopCollect: [
          {required: true, message: this.$t('店铺.店铺收藏数量') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        attentionNum: [
          {required: true, message: this.$t('店铺.关注数量') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        shopAccount: [
          {required: true, message: this.$t('店铺.店铺账户余额') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        /*contactsName: [
          {required: true, message: this.$t('店铺.') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        contactsPhone: [
          {required: true, message: this.$t('店铺.') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],*/
        /*contactsEmail: [
          {required: true, message: this.$t('店铺.') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],*/
        /*contactsCardNo: [
          {required: true, message: this.$t('店铺.申请人身份证号') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        contactsCardHand: [
          {required: true, message: this.$t('店铺.申请人手持身份证电子版') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        contactsCardFront: [
          {required: true, message: this.$t('店铺.申请人身份证正面') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        contactsCardContrary: [
          {required: true, message: this.$t('店铺.申请人身份证反面') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        businessLicenceNumber: [
          {required: true, message: this.$t('店铺.营业执照号') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        companyName: [
          {required: true, message: this.$t('店铺.公司名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        businessSphere: [
          {required: true, message: this.$t('店铺.法定经营范围') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        businessLicenceNumberElectronic: [
          {required: true, message: this.$t('店铺.营业执照电子版') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        settlementBankAccountNumber: [
          {required: true, message: this.$t('店铺.') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        settlementBankAccountName: [
          {required: true, message: this.$t('店铺.结算银行开户名') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        settlementBankName: [
          {required: true, message: this.$t('店铺.结算开户银行支行名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        settlementBankAddress: [
          {required: true, message: this.$t('店铺.结算开户银行所在地') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ]*/
      }
    }
  },
  filters: {},
  created() {
    this.getCategoryList()
    this.getShopQcListList()
    this.getTimeOfEntryList()

  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.handleUpdate(this.form.id)
    } else {
      this.handleAdd()
    }
  },
  methods: {
    container() {
      return this.$refs.container;
    },
    back() {
      let pages = this.$store.pages;
      pages.indexOf(this.$route)
      events.$emit('close', pages.indexOf(this.$route))
      // 去更新列表页的数据
      bus.$emit('updateShopList')
      this.$router.push({path: '/shop/shop', replace: true, query:{}  })
    },
    limitPrice() {
      // console.log(i);
      let val = this.form.platformRatio.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 50) {
        val = 50
      }
      this.form.platformRatio = val
    },
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset() {
      this.categorySelected = [];
      // this.shopQcList=[];
      this.formType = 1
      this.form = {
        id: null,
        shopName: null,
        shopLogo: null,
        shopBackground: null,
        shopKeywords: null,
        shopQc: null,
        timeOfEntry: null,
        shopAreap: null,
        shopAreac: null,
        shopAreax: null,
        shopAddress: null,
        longitude: null,
        latitude: null,
        shopDescription: null,
        kfPhone: null,
        companyType: null,
        settleType: null,
        shopType: null,
        shopRecommend: null,
        userId: null,
        categoryId: null,
        categoryListId: null,
        shopState: null,
        shopCloseInfo: null,
        shopCollect: null,
        attentionNum: null,
        shopAccount: null,
        settlementAccount: null,
        shopCreateTime: null,
        shopEndTime: null,
        goodsDescribeScore: null,
        shopDescribeScore: null,
        logisticsScore: null,
        shopScore: null,
        thatViews: null,
        kfAccount: null,
        alarmGoodsStock: null,
        alarmGoodsSkuStock: null,
        weatherBzj: null,
        moneyBzj: null,
        platformRatio: null,
        contactsName: null,
        contactsPhone: null,
        contactsEmail: null,
        contactsCardNo: null,
        contactsCardHand: null,
        contactsCardFront: null,
        contactsCardContrary: null,
        businessLicenceNumber: null,
        companyName: null,
        companyAreap: null,
        companyAreac: null,
        companyAreax: null,
        companyAddressDetail: null,
        socialCreditCode: null,
        authCertificate: null,
        businessSphere: null,
        businessLicenceNumberElectronic: null,
        bankCardNoType: null,
        settlementBankUserName: null,
        settlementBankAccountNumber: null,
        bankUserPhone: null,
        settlementBankAccountName: null,
        settlementBankName: null,
        settlementBankAddress: null,
        accountInstCity: null,
        accountInstProvince: null,
        createTime: null,
        remark: null,
        trademarkCertificate: null,
        shopProtocol: null,
        businessLicense: null
      }

    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    async handleUpdate(id) {
      this.reset()
      this.formType = 2
      this.getCategoryList()
      getShop({"id": id}).then(response => {

        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
        //回显出分类
        console.log(this.categoryList, response.data.categoryList, response.data.categoryListId)

        this.categorySelected = response.data.categoryList.map(item => String(item))
        console.log(1111111, this.form, this.categoryList)
        return
        this.categoryList.forEach(e => {
          if (e.children) {
            e.children.forEach(child => {
              if (child.id === this.form.categoryId) {
                this.categorySelected.push(e.id)
                this.categorySelected.push(child.id)
              }
            });
          }
          /*console.log(11111)
          console.log(e.children.children)
          if (e.children.children) {
            e.children.children.forEach(child => {
              if (child.id === this.form.categoryId) {
                this.categorySelected.push(e.id)
                this.categorySelected.push(child.id)
              }
            });
          }*/
        });
      })

    },
    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cate'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'shopLogo', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // 上传覆盖默认的上传行为
    coverImgUpload1(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cate'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'shopBackground', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // 上传覆盖默认的上传行为
    coverImgUpload2(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cate'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'contactsCardFront', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // 上传覆盖默认的上传行为
    coverImgUpload3(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cate'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'contactsCardContrary', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // 上传覆盖默认的上传行为
    coverImgUpload4(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cate'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'businessLicenceNumberElectronic', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束
    //多图上传开始
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    // 上传详情覆盖默认的上传行为
    coverImgCaseUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'advert'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.imgCaseList.push({status: 'done', url: res, uid: this.getUidRandom(), name: res})
          console.log(_this.imgCaseList)
          this.form.imgCaseList = []
          _this.imgCaseList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              this.form.imgCaseList.push(e.url);
            }
          })
          _this.$message.success('上传成功')
        })
      })
    },
    // 删除图片
    removeImgCaseFile(file) {
      var fileList = []
      this.imgCaseList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      var imgCaseList = []
      this.imgCaseList = fileList
      fileList.forEach(e => {
        imgCaseList.push(e.url)
      })
      this.form.imgCaseList = imgCaseList
    },
    //多图上传结束
    getSettleTypeList() {
      allBrand().then(response => {
        this.categoryList = response.data
      })
    },
    /*页面初始化时获取分类类别*/
    getCategoryList() {
      listShopCategory().then(response => {
        this.categoryList = response.data;
        let children = this.handleTree(response.data, 'id', 'pid')
        console.log('children', children)
        this.categoryList = children
      })
    },
    /*分类的选择*/
    onCategoryChange(value, selectedOptions) {
      this.form.categoryId = value[value.length-1]
    },
    getShopQcListList() {
      listShopQc().then(response => {
        this.shopQcList = response.data
      })
    },
    getTimeOfEntryList() {
      listShopEntry().then(response => {
        this.timeOfEntryList = response.data
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          this.form.categoryListId = this.categorySelected
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateShop(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addShop(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less">
.goods-collapse > .ant-collapse-header {
  color: #007ffd !important;
  background: #e8eef9;
}

.box {
  position: relative;
  background-color: #f7f7f7;
  padding: 0 15px 15px 15px;
  margin-bottom: 15px;
  border-radius: 5px;

  .delSpec {
    position: absolute;
    right: -5px;
    top: -5px;
    color: #f5222d;
    cursor: pointer;
  }
}

</style>